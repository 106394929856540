import { combineReducers } from 'redux';
import authReducers from './authReducers';
import cargoReducers from './cargoReducers';
import errorReducers from './errorReducers';
import userReducers from './userReducers';
import shipReducers from './shipReducers';
import travelReducers from './travelReducer';

export default combineReducers({
	auth: authReducers,
	user: userReducers,
	errors: errorReducers,
	cargo: cargoReducers,
	ship: shipReducers,
	travel: travelReducers,
});
