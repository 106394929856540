import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import PropTypes from 'prop-types';

export class Register extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			email: '',
			password: '',
			password2: '',
			errors: {},
		};
	}

	componentDidMount() {
		// Check if is logged in
		if (this.props.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.errors !== prevProps.errors) {
			this.setState({ errors: this.props.errors });
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = (e) => {
		e.preventDefault();

		const newUser = {
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			password2: this.state.password2,
		};
		this.props.registerUser(newUser, this.props.history);
	};

	render() {
		const { errors } = this.state;
		return (
			<div className="container">
				<div className="row">
					<div className="col s8 offset-s2">
						<div className="col s12" style={{ paddingLeft: '11.250px' }}>
							<h4>
								<b>Register</b> below
							</h4>
							<p className="grey-text text-darken-1">
								Already have an account? <Link to="/auth/login">Log in</Link>
							</p>
							<p className="text-danger">{errors.user}</p>
						</div>
						<form noValidate onSubmit={this.onSubmit}>
							<label htmlFor="name">Name</label>
							<div className="input-field col s12">
								<input onChange={this.onChange} value={this.state.name} id="name" type="text" />
							</div>
							<label htmlFor="email">Email</label>
							<div className="input-field col s12">
								<input onChange={this.onChange} value={this.state.email} id="email" type="email" />
							</div>
							<label htmlFor="password">Password</label>
							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.password}
									id="password"
									type="password"
								/>
							</div>
							<label htmlFor="password2">Confirm Password</label>

							<div className="input-field col s12">
								<input
									onChange={this.onChange}
									value={this.state.password2}
									id="password2"
									type="password"
								/>
							</div>
							<div className="col s12" style={{ paddingLeft: '11.250px' }}>
								<button
									style={{
										width: '150px',
										borderRadius: '3px',
										letterSpacing: '1.5px',
										marginTop: '1rem',
									}}
									type="submit"
									className="btn btn-primary"
								>
									Sign up
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

Register.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
});

const mapDispatchToProps = {
	registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
