import React, { Component } from 'react';
import ReactTable from 'react-table-6';

import 'react-table-6/react-table.css';
import { getAllUsers } from '../actions/authActions';

export class UsersList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			columns: [],
			isLoading: false,
		};
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true });

		getAllUsers().then((users) => {
			this.setState({
				users: users,
				isLoading: false,
			});
		});
	};

	render() {
		const { users, isLoading } = this.state;

		const columns = [
			{
				Header: 'Name',
				accessor: 'name',
				filterable: true,
			},
			{
				Header: 'Doubloon',
				accessor: 'doubloon',
				filterable: true,
			},
		];

		let showTable = true;
		if (!users.length) {
			showTable = false;
		}

		return (
			<>
				{showTable && (
					<ReactTable
						data={users}
						columns={columns}
						loading={isLoading}
						defaultPageSize={10}
						showPageSizeOptions={true}
						minRows={0}
					/>
				)}
			</>
		);
	}
}

export default UsersList;
