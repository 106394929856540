import React, { Component } from 'react';

import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

export class Logo extends Component {
	render() {
		return (
			<Link to="/" className="navbar-brand">
				<img src={logo} width="50" height="50" alt="T" />
				TradeGame
			</Link>
		);
	}
}

export default Logo;
