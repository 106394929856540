import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDestinations, startTravel } from '../../actions/travelActions';
import { isEmpty } from 'lodash';

class Inventory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: props.user,
			error: props.error,
			destinations: [],
		};

		this.props.getDestinations().then((data) => {
			this.setState({ destinations: data });
		});
	}

	handleTravelButton(destination) {
		this.props.startTravel({ destination: destination });
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.setState({ user: this.props.user });
		}
	}

	render() {
		const destinations = this.state.destinations;
		const user = this.state.user;
		if (destinations === undefined) {
			return (
				<>
					<b>Loading...</b>
				</>
			);
		}
		const travelButton = (destination) => {
			if (destination !== user.location) {
				return (
					<button className="btn btn-info" onClick={() => this.handleTravelButton(destination)}>
						Travel
					</button>
				);
			}
		};
		return (
			<>
				<p className="text-danger">{!isEmpty(this.props.error.travel) && this.props.error.travel}</p>

				{destinations.map((item) => {
					return (
						<div
							key={item.location}
							style={{ borderBottom: '1px dotted black', paddingBottom: '10px', paddingTop: '10px' }}
						>
							{item.location} - {item.distance} {travelButton(item.location)}
						</div>
					);
				})}
			</>
		);
	}
}

Inventory.propTypes = {
	user: PropTypes.object.isRequired,
	getDestinations: PropTypes.func.isRequired,
	startTravel: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	user: state.user,
	error: state.errors,
});

const mapDispatchToProps = {
	getDestinations,
	startTravel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
