import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Inventory, ShipInformation, ShopTab } from './dashboard-tabs';
import TravelTab from './dashboard-tabs/TravelTab';

class DashboardOverview extends Component {
	render() {
		return (
			<>
				<ul className="nav nav-tabs" id="myTab" role="tablist">
					<li className="nav-item">
						<a
							className="nav-link active"
							id="shop-tab"
							data-toggle="tab"
							href="#shop"
							role="tab"
							aria-controls="shop"
							aria-selected="true"
						>
							Shop
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="travel-tab"
							data-toggle="tab"
							href="#travel"
							role="tab"
							aria-controls="travel"
							aria-selected="false"
						>
							Travel
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="inventory-tab"
							data-toggle="tab"
							href="#inventory"
							role="tab"
							aria-controls="inventory"
							aria-selected="false"
						>
							Inventory
						</a>
					</li>
					<li className="nav-item">
						<a
							className="nav-link"
							id="ship-tab"
							data-toggle="tab"
							href="#ship"
							role="tab"
							aria-controls="ship"
							aria-selected="false"
						>
							Ship
						</a>
					</li>
				</ul>
				<div className="tab-content mt-3" id="myTabContent">
					<div className="tab-pane fade show active" id="shop" role="tabpanel" aria-labelledby="shop-tab">
						<ShopTab />
					</div>
					<div className="tab-pane fade" id="travel" role="tabpanel" aria-labelledby="travel-tab">
						<TravelTab />
					</div>
					<div className="tab-pane fade" id="inventory" role="tabpanel" aria-labelledby="inventory-tab">
						<Inventory />
					</div>
					<div className="tab-pane fade" id="ship" role="tabpanel" aria-labelledby="ship-tab">
						<ShipInformation />
					</div>
				</div>
			</>
		);
	}
}

export default connect()(DashboardOverview);
