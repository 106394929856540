import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	getSingleShipInformation,
	getAllShipInformation,
	getUserShipInformation,
	repairUserShip,
	buyUserShip,
	buyCannonUser,
	sellCannonUser,
} from '../../actions/shipActions';
import doubloon from '../../assets/doubloon.png';
import { updateUser } from '../../actions/authActions';
import { isEmpty } from 'lodash';

class ShipInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: props.user,
			userShip: props.ship,
			allShips: [],
			error: '',
		};

		this.props.getAllShipInformation().then((data) => {
			if (data !== undefined) {
				this.setState({ allShips: data.ships });
				this.setState({ cannonPrices: data.canonPrices });
				this.updateGeneralShip();
			}
		});

		this.props.getUserShipInformation();
	}

	updateGeneralShip(id = undefined) {
		if (this.state.allShips.length > 0) {
			const userShipId = id === undefined ? this.props.userShip.id : id;
			const shipInfo = this.state.allShips.find((s) => s.id === userShipId);
			this.setState({ ship: shipInfo });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.userShip !== prevProps.userShip || this.props.cargo !== prevProps.cargo) {
			this.setState({ userShip: this.props.userShip, cargo: this.props.cargo });

			if (this.state.ship === undefined && this.props.userShip !== undefined && this.state.allShips.length > 0) {
				this.updateGeneralShip();
			}
		}
	}

	handleRepairShipButton = () => {
		this.props.repairUserShip();
		this.props.updateUser();
	};

	handleBuyShipButton = (shipId) => {
		if (shipId !== undefined) {
			this.props
				.buyUserShip(shipId)
				.then(() => {
					this.updateGeneralShip(shipId);
					this.props.updateUser();
				})
				.catch(() => {
					this.setState({ error: this.props.error });
				});
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	handleBuyCannonButton = (key) => {
		if (key === undefined) return;
		const amount = this.state['buy' + key];
		if (amount === undefined) return;

		this.props
			.buyCannonUser({
				cannonAmount: amount,
				cannonSize: key,
			})
			.then(() => {
				this.props.updateUser();
			})
			.catch(() => {
				this.setState({ error: this.props.error });
			});
	};

	handleSellCannonButton = (key) => {
		if (key === undefined) return;
		const amount = this.state['sell' + key];
		if (amount === undefined) return;

		this.props
			.sellCannonUser({
				cannonAmount: amount,
				cannonSize: key,
			})
			.then(() => {
				this.props.updateUser();
			})
			.catch(() => {
				this.setState({ error: this.props.error });
			});
	};

	render() {
		const userShipInformation = this.state.userShip;
		const shipInformation = this.state.ship;
		const cargoInformation = this.state.cargo;
		const allShips = this.state.allShips;
		const user = this.state.user;
		if (
			shipInformation === undefined ||
			userShipInformation === undefined ||
			user === undefined ||
			cargoInformation === undefined ||
			allShips.length === 0
		)
			return (
				<>
					<b>Loading...</b>
				</>
			);

		const cargoAmount = () => {
			let count = 0;
			cargoInformation.forEach((c) => {
				count += c.amount;
			});
			return count;
		};

		const repairShipButton = () => {
			const pricePerHealthTick = 50;
			if (userShipInformation.health > shipInformation.health) {
				const price = (shipInformation.health - userShipInformation.health) * pricePerHealthTick;
				return (
					<button className="btn btn-info" onClick={this.handleRepairShipButton}>
						Repair ship ({price}
						<img src={doubloon} alt="D" />)
					</button>
				);
			}
		};
		const buyButton = (id, price) => {
			if (price < user.doubloon && shipInformation.id !== id) {
				return (
					<button className="btn btn-info" onClick={() => this.handleBuyShipButton(id)}>
						Buy
					</button>
				);
			}

			return (
				<button className="btn btn-danger" disabled>
					Buy
				</button>
			);
		};

		return (
			<>
				<h2>Ship information</h2>
				<hr />
				<h4>Current Ship Information</h4>
				<p>
					You currently own the: {shipInformation.type} <small>({shipInformation.extra})</small>
				</p>
				<p>
					The maximum storage is {shipInformation.storage} items. You're currently storing {cargoAmount()}{' '}
					items.
				</p>
				<p>
					The current health is {userShipInformation.health} out of {shipInformation.health}.{' '}
					{repairShipButton()}
				</p>
				<p>The maximum amount of cannons you can own is {shipInformation.cannons}.</p>
				<p>
					You're able to use the following cannon types:{' '}
					{shipInformation.cannonSize && shipInformation.cannonSize.map((item) => item)}
				</p>
				<hr />
				<h4>Buy/Sell Cannons</h4>
				<p className="text-danger">{!isEmpty(this.props.error.cannon) && this.props.error.cannon}</p>

				<div className="row">
					{Object.entries(this.state.cannonPrices).map((v, k) => {
						if (shipInformation.cannonSize.includes(v[0])) {
							return (
								<div
									className="col-md-5"
									key={v[0]}
									style={{ border: '1px dotted black', padding: '10px', margin: '20px' }}
								>
									<b>Size</b>: {v[0]}
									<br />
									<b>Price</b>: {v[1]}
									<img src={doubloon} alt="D" />
									<br />
									<b>You currently have</b>: {userShipInformation.cannons[v[0]]}
									<br />
									<input
										type="number"
										style={{ width: '50px' }}
										min={0}
										max={this.state.ship.cannons}
										defaultValue={0}
										id={'buy' + v[0]}
										onChange={this.onChange}
									/>
									<button
										className="btn btn-success"
										onClick={() => this.handleBuyCannonButton(v[0])}
									>
										Buy
									</button>
									<br />
									<br />
									<input
										type="number"
										style={{ width: '50px' }}
										min={0}
										max={this.state.ship.cannons}
										defaultValue={0}
										id={'sell' + v[0]}
										onChange={this.onChange}
									/>
									<button
										className="btn btn-danger"
										onClick={() => this.handleSellCannonButton(v[0])}
									>
										Sell
									</button>
								</div>
							);
						}
						return <div key={v[0]}></div>;
					})}
				</div>
				<hr />
				<h4>Buy a ship</h4>
				<p className="text-danger">{!isEmpty(this.props.error.ship) && this.props.error.ship}</p>
				<div className="row">
					{allShips.map((ship) => {
						return (
							<div
								className="col-md-5"
								key={ship.id}
								style={{ border: '1px dotted black', padding: '10px', margin: '20px' }}
							>
								<h5>
									{ship.type} {buyButton(ship.id, ship.price)}{' '}
								</h5>{' '}
								<small>{ship.extra}</small>
								<br />
								<strong>Health</strong> {ship.health}
								<br />
								<strong>Price</strong> {ship.price} <img src={doubloon} alt="D" />
								<br />
								<strong>Max Cannons</strong> {ship.cannons}
								<br />
								<strong>Max Storage</strong> {ship.storage}
								<br />
								<strong>Cannon Types:</strong>{' '}
								{ship.cannonSize &&
									ship.cannonSize.map((item) => {
										return item + ' ';
									})}
								<br />
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

ShipInformation.propTypes = {
	user: PropTypes.object.isRequired,
	userShip: PropTypes.object.isRequired,
	cargo: PropTypes.array.isRequired,
	getUserShipInformation: PropTypes.func.isRequired,
	getSingleShipInformation: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
	return {
		user: state.user,
		userShip: state.ship,
		cargo: state.cargo,
		error: state.errors,
	};
};

const mapDispatchToProps = {
	getUserShipInformation,
	getSingleShipInformation,
	getAllShipInformation,
	buyUserShip,
	buyCannonUser,
	sellCannonUser,
	repairUserShip,
	updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipInformation);
