import React, { Component } from 'react';
import { connect } from 'react-redux';

class UserHighscores extends Component {
	constructor(props) {
		super(props);

		this.state = {
			highscores: this.props.highscores,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.highscores !== prevProps.highscores) {
			this.setState({ highscores: this.props.highscores });
		}
	}

	render() {
		const highscores = this.state.highscores;
		if (highscores === undefined) {
			return (
				<>
					<b>Loading...</b>
				</>
			);
		}
		return (
			<>
				<h1>Your previous highscores</h1>
				<hr />
				<div className="row">
					{highscores.map((score) => {
						const date = new Date(Date.parse(score.time));
						return (
							<div
								key={score._id}
								style={{ borderBottom: '1px dotted black', width: '100%', marginTop: '20px' }}
							>
								<b>Score:</b> {score.score}
								<br />
								<b>Achieved on:</b> {date.toLocaleDateString()} {date.toLocaleTimeString()}
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	highscores: state.user.previousHighScores,
});

export default connect(mapStateToProps)(UserHighscores);
