import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserInventory } from '../../actions/inventoryActions';

class Inventory extends Component {
	constructor(props) {
		super(props);
		this.props.getUserInventory();

		this.state = {
			cargo: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.cargo !== prevProps.cargo) {
			this.setState({ cargo: this.props.cargo });
		}
	}

	render() {
		const cargo = this.state.cargo;
		if (cargo === undefined) {
			return (
				<>
					<b>Loading...</b>
				</>
			);
		}
		return (
			<>
				You currently have the following items in your ship
				<hr />
				<div className="row">
					{cargo.map((item) => {
						return (
							<div
								className="col-md-5"
								key={item._id}
								style={{ border: '1px dotted black', padding: '10px', margin: '20px' }}
							>
								<img
									src={'./assets/' + item.productName + '.png'}
									style={{ maxWidth: '30%', float: 'right' }}
									alt={item.productName}
								/>
								<b>Name</b>: {item.productName} <br />
								<b>On your boat: </b> {item.amount} x<br />
							</div>
						);
					})}
				</div>
			</>
		);
	}
}
Inventory.propTypes = {
	getUserInventory: PropTypes.func.isRequired,
	cargo: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
	cargo: state.cargo,
});

const mapDispatchToProps = {
	getUserInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
