import axios from 'axios';

import { GET_ERRORS, SET_TRAVEL_ARRIVED, SET_TRAVEL_INFORMATION, SET_TRAVEL_STEPS } from './types';

// Get all the destinations
export const getDestinations = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.get('api/travel/getDestinations')
			.then((res) => {
				return resolve(res.data.data);
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				return reject();
			});
	});
};

// Start with the travel
export const startTravel = (data) => async (dispatch) => {
	await axios
		.post('api/travel/startTravel', data)
		.then((res) => {
			console.log(res.data);
			// set steps
			dispatch(setTravelInformation(res.data));
			dispatch(setTravelArrivedStatus({ arrived: false }));
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const doTravel = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/travel/doStep')
			.then((res) => {
				// set steps
				dispatch(setStepsToGo(res.data));

				return resolve(res.data);
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				return reject();
			});
	});
};

export const piratesFlee = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/travel/doPiratesFlee')
			.then((res) => {
				// set steps
				dispatch(setStepsToGo(res.data));
				return resolve(res.data);
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				return reject();
			});
	});
};

export const piratesShoot = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/travel/doPiratesShoot')
			.then((res) => {
				// set steps
				dispatch(setStepsToGo(res.data));
				return resolve(res.data);
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				return reject();
			});
	});
};

export const updateTravelStatus = (data) => (dispatch) => {
	dispatch(setTravelArrivedStatus(data));
};

export const setTravelInformation = (decoded) => {
	return {
		type: SET_TRAVEL_INFORMATION,
		payload: decoded,
	};
};

export const setTravelArrivedStatus = (decoded) => {
	return {
		type: SET_TRAVEL_ARRIVED,
		payload: decoded,
	};
};

export const setStepsToGo = (decoded) => {
	return {
		type: SET_TRAVEL_STEPS,
		payload: decoded,
	};
};
