export const GET_ERRORS = 'GET_ERRORS';
export const GET_SINGLE_SHIP_INFORMATION = 'GET_SINGLE_SHIP_INFORMATION';
export const SET_CURRENT_TOKEN_INFO = 'SET_CURRENT_TOKEN_INFO';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_SHIP = 'SET_SHIP';
export const SET_TRAVEL_ARRIVED = 'SET_TRAVEL_ARRIVED';
export const SET_TRAVEL_INFORMATION = 'SET_TRAVEL_INFORMATION';
export const SET_TRAVEL_STEPS = 'SET_TRAVEL_STEPS';
export const USER_LOADING = 'USER_LOADING';
