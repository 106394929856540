import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_TOKEN_INFO, SET_CURRENT_USER, USER_LOADING } from './types';
import { setShip } from './shipActions';
import { setInventory } from './inventoryActions';
import { setTravelArrivedStatus, setTravelInformation } from './travelActions';

// Register User
export const registerUser = (userData, history) => (dispatch) => {
	axios
		.post('/api/users/register', userData)
		.then((res) => {
			dispatch({ type: GET_ERRORS, payload: {} });
			return history.push('/auth/login');
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

// Login - Get user Token
export const loginUser = (userData) => (dispatch) => {
	axios
		.post('/api/users/login', userData)
		.then((res) => {
			// Save to localStrage
			const { token } = res.data;
			localStorage.setItem('jwtToken', token);
			// set Token to Auth Header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// set current user
			dispatch({ type: GET_ERRORS, payload: {} });
			dispatch(setCurrentUserToken(decoded));
			dispatch(updateUser());
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const getAllUsers = () => (dispatch) => {
	return new Promise((resolve) => {
		axios
			.get('/api/users/users')
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			});
	});
};
export const resetUser = () => (dispatch) => {
	axios
		.get('/api/users/resetUser')
		.then((res) => {
			dispatch(updateUser());
		})
		.catch((err) => {
			console.log(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.message,
			});
		});
};
// Login - Get user Token
export const updateUser = () => (dispatch) => {
	axios
		.get('/api/users/info')
		.then((res) => {
			// set current user
			dispatch({ type: GET_ERRORS, payload: {} });
			dispatch(setCurrentUser(res.data.data));
			dispatch(setShip(res.data.data.ship));
			dispatch(setInventory(res.data.data.cargo));
			dispatch(setTravelInformation(res.data.data.travel));
			dispatch(setTravelArrivedStatus({ arrived: res.data.data.travel.stepsToGo === 0 }));
		})
		.catch((err) => {
			console.log(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.message,
			});
		});
};

// set logged in user token
export const setCurrentUserToken = (decoded) => {
	return {
		type: SET_CURRENT_TOKEN_INFO,
		payload: decoded,
	};
};

// set logged in user
export const setCurrentUser = (decoded) => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded,
	};
};

// User Loading
export const setUserLoading = () => {
	return {
		type: USER_LOADING,
	};
};

// Log user out
export const logoutUser = () => (dispatch) => {
	// Remove token from local storage
	localStorage.removeItem('jwtToken');
	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to empty object {} which will set isAuthenticated to false
	setShip(null);
	setInventory(null);
	dispatch(setCurrentUser({}));
	dispatch(setCurrentUserToken({}));
	dispatch(setShip({}));
	dispatch(setInventory([]));
	dispatch(setTravelInformation({}));
};
