import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from '../store';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { logoutUser, setCurrentUserToken, updateUser } from '../actions/authActions';

import { NavBar } from '../components';
import { UsersList } from '../pages';
import { Login, Register } from '../pages/auth';
import PrivateRoute from '../components/private-route/PrivateRoute';
import Dashboard from '../pages/dashboard/Dashboard';
import styled from 'styled-components';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from '../pages/HomePage';
import UserHighscores from '../pages/UserHighscores';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
	// Set auth token header auth
	const token = localStorage.jwtToken;
	setAuthToken(token);
	// Decode token and get user info and exp
	const decoded = jwt_decode(token);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUserToken(decoded)); // Check for expired token
	store.dispatch(updateUser()); // Redirect to login

	const currentTime = Date.now() / 1000; // to get in milliseconds
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser()); // Redirect to login
		window.location.href = './login';
	}
}

const Container = styled.div.attrs({
	className: 'container',
})``;

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Router>
					<NavBar />
					<Container>
						<Switch>
							<Route path="/auth/login" exact component={Login} />
							<Route path="/auth/register" exact component={Register} />
							<Route path="/" exact component={HomePage} />
							<Switch>
								<PrivateRoute exact path="/dashboard" component={Dashboard} />
								<PrivateRoute exact path="/user/highscores" component={UserHighscores} />
								<PrivateRoute path="/users/list" exact component={UsersList} />
							</Switch>
						</Switch>
					</Container>
				</Router>
			</Provider>
		);
	}
}

export default App;
