import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import doubloon from '../../assets/doubloon.png';
import { isEmpty } from 'lodash';
import DashboardOverview from '../../components/DashboardOverview';
import TravelScreen from '../../components/TravelScreen';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			travel: this.props.travel,
			user: this.props.user,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.setState({ user: this.props.user });
		}

		if (this.props.travel !== prevProps.travel) {
			this.setState({ travel: this.props.travel });
		}
	}

	render() {
		const user = this.state.user;
		const travel = this.state.travel;
		if (isEmpty(user) || isEmpty(travel)) {
			return <></>;
		}
		return (
			<div className="container mt-2">
				<div className="row">
					<div className="col-sm-12 center-align">
						<h4>
							<b>Hey there,</b> {user.name.split(' ')[0]}
						</h4>
						<p>
							You currently have {user.doubloon} <img src={doubloon} alt="D" />
						</p>
						<p>You're currently in {user.location}</p>

						<hr />

						{travel.stepsToGo === 0 && travel.arrived ? (
							<>
								<p>
									<b>Shop</b> This is the place where you can buy and sell cargo in the town
								</p>
								<p>
									<b>Travel</b> This is the place where you can sail to a different town
								</p>
								<p>
									<b>Inventory</b> This is the place where you can view your cargo
								</p>
								<p>
									<b>Ship</b> This is the place where you can upgrade, repair and buy a new ship
								</p>
								<hr />
								<DashboardOverview />
							</>
						) : (
							<>
								<TravelScreen travel={travel} />
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}

Dashboard.propTypes = {
	user: PropTypes.object.isRequired,
	travel: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
	user: state.user,
	travel: state.travel,
});

export default connect(mapStateToProps)(Dashboard);
