import { SET_CURRENT_TOKEN_INFO, USER_LOADING } from '../actions/types';

import isEmpty from 'is-empty';

const initialState = {
	isAuthenticated: false,
	tokenInfo: {},
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_TOKEN_INFO:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				tokenInfo: action.payload,
			};
		case USER_LOADING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
