import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doTravel, piratesFlee, piratesShoot, updateTravelStatus } from '../actions/travelActions';
import { resetUser, updateUser } from '../actions/authActions';
import { getUserShipInformation } from '../actions/shipActions';

class TravelScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stepInfo: '',
			arrived: false,
			sunk: false,
			shipInformation: this.props.ship,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.ship !== prevProps.ship) {
			this.setState({ shipInformation: this.props.ship });
		}
	}

	doStep = () => {
		this.props.doTravel().then((data) => {
			this.props.getUserShipInformation();
			this.setState({ stepInfo: data.travel, arrived: data.arrived, sunk: data.sunk });
		});
	};

	doPiratesFlee = () => {
		this.props.piratesFlee().then((data) => {
			this.props.getUserShipInformation();
			this.setState({ stepInfo: data.travel, arrived: data.arrived, sunk: data.sunk });
		});
	};
	doPiratesShoot = () => {
		this.props.piratesShoot().then((data) => {
			this.props.getUserShipInformation();
			this.setState({ stepInfo: data.travel, arrived: data.arrived, sunk: data.sunk });
		});
	};

	doEnterTown = () => {
		this.props.updateTravelStatus({ arrived: true });
		this.props.updateUser();
	};

	resetGame = () => {
		this.props.resetUser();
		this.props.updateTravelStatus({ arrived: true });
	};

	render() {
		const travel = this.props.travel;
		let stepInfo = this.state.stepInfo;
		const arrived = this.state.arrived;
		const sunk = this.state.sunk;
		const shipInformation = this.state.shipInformation;

		if (this.state.shipInformation === undefined || travel.status === undefined) {
			return (
				<>
					<b>Loading...</b>p
				</>
			);
		}
		if (stepInfo === '') stepInfo = travel.lastMessage;

		const doStepOrResetBtn = () => {
			if (arrived && shipInformation.health > 0) {
				return (
					<button className="btn btn-primary" onClick={() => this.doEnterTown()}>
						Enter town
					</button>
				);
			} else {
				return (
					<button className="btn btn-primary" onClick={() => this.doStep()}>
						Do step
					</button>
				);
			}
		};
		if (sunk || shipInformation.health <= 0) {
			return (
				<>
					<h2>Game over</h2>
					<h4>Your ship sunk.</h4>
					<p>{stepInfo}</p>

					<button className="btn btn-primary" onClick={() => this.resetGame()}>
						Reset game
					</button>
				</>
			);
		} else {
			if (travel.status === 'traveling') {
				return (
					<>
						<h2>You're traveling to {travel.destination}</h2>
						<h4>You've {travel.stepsToGo} steps to go</h4>
						Your ship has {shipInformation.health} HP left.
						<br />
						<p>{stepInfo}</p>
						{doStepOrResetBtn()}
					</>
				);
			} else if (travel.status.startsWith('pirates')) {
				return (
					<>
						<h2>You're traveling to {travel.destination}</h2>
						<h4>You've {travel.stepsToGo} steps to go</h4>
						Your ship has {shipInformation.health} HP left. <br />
						{stepInfo}
						<br />
						<br />
						<button className="btn btn-primary" onClick={() => this.doPiratesFlee()}>
							Try to flee
						</button>
						{(shipInformation.cannons.small !== 0 ||
							shipInformation.cannons.medium !== 0 ||
							shipInformation.cannons.large !== 0) && (
							<button
								className="btn btn-primary"
								style={{ marginLeft: '20px' }}
								onClick={() => this.doPiratesShoot()}
							>
								Shoot with your cannon(s)
							</button>
						)}
					</>
				);
			} else {
				return <></>;
			}
		}
	}
}

const mapStateToProps = (state) => ({
	ship: state.ship,
});

const mapDispatchToProps = {
	doTravel,
	piratesFlee,
	piratesShoot,
	updateUser,
	updateTravelStatus,
	getUserShipInformation,
	resetUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelScreen);
