import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class HomePage extends Component {
	constructor(props) {
		super(props);
		if (props.auth.isAuthenticated) {
			props.history.push('/dashboard');
		}
	}

	render() {
		return (
			<div>
				<h1>Trade Game</h1>
				<h3>Are you able to make the most profit?</h3>
				<hr />
				<p>TradeGame is about making a profit while trading.</p>
				<p>
					You can sail between towns and buy and sell your cargo. Each town offers the items with different
					prices.
				</p>
				<p>
					But be careful, the sea is a dangerous. Pirates might attack your boat, or you might end up in an
					hurricane.
				</p>
				<hr />
				<div className="row">
					<div className="col-md-4">
						<h4 style={{ textAlign: 'center' }}>Sail</h4>

						<p>Travel between different cities, each city has their own prices.</p>
					</div>
					<div className="col-md-4" style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
						<h4 style={{ textAlign: 'center' }}>Make profit</h4>
						<p>Sell your cargo in a different city with a higher price to make the most profit</p>
					</div>
					<div className="col-md-3">
						<h4 style={{ textAlign: 'center' }}>Score</h4>
						<p>Score the highest score and beat your friends their score</p>
					</div>
				</div>
			</div>
		);
	}
}

HomePage.propTypes = {
	auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps)(HomePage);
