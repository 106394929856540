import axios from 'axios';

import { GET_ERRORS, SET_INVENTORY } from './types';

// Get and set the inventory of the user
export const getUserInventory = () => async (dispatch) => {
	await axios
		.get('api/inventory/getAll')
		.then((res) => {
			// set inventory
			dispatch(setInventory(res.data.data));
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const getAllShopItems = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.get('api/inventory/getUserShop')
			.then((res) => {
				return resolve(res.data.productList);
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				return reject();
			});
	});
};

export const buyCargoUser = (buyData) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/inventory/buy', buyData)
			.then((res) => {
				// set inventory
				dispatch(setInventory(res.data.cargo));
				return resolve();
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				reject();
			});
	});
};

export const sellCargoUser = (sellData) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/inventory/sell', sellData)
			.then((res) => {
				// set inventory
				dispatch(setInventory(res.data.cargo));
				return resolve();
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				reject();
			});
	});
};

export const setInventory = (decoded) => {
	return {
		type: SET_INVENTORY,
		payload: decoded,
	};
};
