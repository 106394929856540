import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutUser } from '../actions/authActions';

const Collapse = styled.div.attrs({
	className: 'collapse navbar-collapse',
})``;

const List = styled.div.attrs({
	className: 'navbar-nav mr-auto',
})``;

const Item = styled.div.attrs({
	className: 'collapse navbar-collapse',
})``;

export class Links extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuthenticated: props.auth.isAuthenticated,
		};
	}

	onLogoutClick = (e) => {
		e.preventDefault();
		this.props.logoutUser();
	};

	componentDidUpdate(prevProps) {
		if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
			this.setState({ isAuthenticated: this.props.auth.isAuthenticated });
		}
	}

	render() {
		const { isAuthenticated } = this.state;

		return (
			<React.Fragment>
				<Collapse>
					{isAuthenticated ? (
						<List>
							<Item>
								<Link to="/user/highscores" className="nav-link">
									Personal highscores
								</Link>
							</Item>
							<Item>
								<Link to="#" onClick={this.onLogoutClick} className="nav-link text-danger">
									Logout
								</Link>
							</Item>
						</List>
					) : (
						<List>
							<Item>
								<Link to="/auth/login" className="nav-link">
									Login
								</Link>
							</Item>
							<Item>
								<Link to="/auth/register" className="nav-link">
									Register
								</Link>
							</Item>
						</List>
					)}
				</Collapse>
			</React.Fragment>
		);
	}
}

Links.propTypes = {
	auth: PropTypes.object.isRequired,
	logoutUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Links);
