import { SET_TRAVEL_STEPS, SET_TRAVEL_INFORMATION, SET_TRAVEL_ARRIVED } from '../actions/types';

const initialState = {
	destination: '',
	stepsToGo: 0,
	status: '',
	lastMessage: '',
	arrived: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_TRAVEL_INFORMATION:
			return {
				...state,
				stepsToGo: action.payload.stepsToGo,
				status: action.payload.status,
				lastMessage: action.payload.lastMessage,
				destination: action.payload.destination,
			};
		case SET_TRAVEL_ARRIVED:
			return {
				...state,
				arrived: action.payload.arrived,
			};
		case SET_TRAVEL_STEPS:
			return {
				...state,
				status: action.payload.status,
				stepsToGo: action.payload.stepsToGo,
			};
		default:
			return state;
	}
}
