import axios from 'axios';

import { GET_ERRORS, SET_SHIP } from './types';

export const getUserShipInformation = () => {
	return (dispatch) => {
		axios
			.get('/api/ship/getUserShip')
			.then((res) => {
				// Set User Ship
				dispatch(setShip(res.data.ship));
				return res.data;
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
			});
	};
};

// Get information about a single ship
export const getSingleShipInformation = (shipId) => async (dispatch) => {
	return await axios
		.post('api/ship/getInfo', shipId)
		.then((res) => res.data)
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const getAllShipInformation = () => async (dispatch) => {
	return await axios
		.get('api/ship/getAll')
		.then((res) => res.data)
		.catch((err) => {
			console.error(err);
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			});
		});
};

export const repairUserShip = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			axios
				.get('api/ship/repair')
				.then((res) => {
					// Set User Ship
					dispatch(setShip(res.data.ship));
					resolve();
				})
				.catch((err) => {
					console.error(err);
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data,
					});
				});
		});
	};
};

export const buyCannonUser = (buyData) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/ship/buyCannon', buyData)
			.then((res) => {
				// set ship
				dispatch(setShip(res.data.ship));
				return resolve();
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				reject();
			});
	});
};

export const sellCannonUser = (sellData) => async (dispatch) => {
	return new Promise((resolve, reject) => {
		axios
			.post('api/ship/sellCannon', sellData)
			.then((res) => {
				// set ship
				dispatch(setShip(res.data.ship));
				return resolve();
			})
			.catch((err) => {
				if (err.response === undefined) return resolve();
				console.error(err);
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				});
				reject();
			});
	});
};

export const buyUserShip = (shipId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			axios
				.post('api/ship/buyShip', { shipId: shipId })
				.then((res) => {
					// Set User Ship
					dispatch(setShip(res.data.ship));
					dispatch({ type: GET_ERRORS, payload: {} });
					return resolve();
				})
				.catch((err) => {
					if (err.response === undefined) return resolve();
					console.error(err.response);
					dispatch({
						type: GET_ERRORS,
						payload: err.response.data,
					});
					reject();
				});
		});
	};
};

export const setShip = (decoded) => {
	return {
		type: SET_SHIP,
		payload: decoded,
	};
};
