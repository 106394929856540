import React, { Component } from 'react';
import { connect } from 'react-redux';
import { buyCargoUser, sellCargoUser, getAllShopItems } from '../../actions/inventoryActions';
import doubloon from '../../assets/doubloon.png';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { updateUser } from '../../actions/authActions';

class ShopTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shopItems: [],
			cargo: [],
			error: '',
		};

		this.props.getAllShopItems().then((data) => {
			this.setState({ shopItems: data });
		});
	}

	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	handleBuyCargoButton = (productName) => {
		if (productName === undefined) return;
		const amount = this.state['buy' + productName];
		if (amount === undefined) return;
		if (amount < 0) return;

		this.props
			.buyCargoUser({
				amount: amount,
				productName: productName,
			})
			.then(() => {
				this.props.getAllShopItems().then((data) => {
					this.setState({ shopItems: data });
				});
				this.props.updateUser();
			})
			.catch(() => {
				this.setState({ error: this.props.error });
			});
	};

	handleSellCargoButton = (productName) => {
		if (productName === undefined) return;
		const amount = this.state['sell' + productName];
		if (amount === undefined) return;

		this.props
			.sellCargoUser({
				amount: amount,
				productName: productName,
			})
			.then(() => {
				this.props.getAllShopItems().then((data) => {
					this.setState({ shopItems: data });
				});
				this.props.updateUser();
			})
			.catch(() => {
				this.setState({ error: this.props.error });
			});
	};

	componentDidUpdate(prevProps) {
		if (this.props.cargo !== prevProps.cargo) {
			this.setState({ cargo: this.props.cargo });
		}
	}

	render() {
		const shopItems = this.state.shopItems;
		const cargo = this.state.cargo;
		if (shopItems === undefined || cargo === undefined) {
			return (
				<>
					<b>Loading...</b>
				</>
			);
		}

		return (
			<>
				<h1>Town Shop</h1>
				<p className="text-danger">{!isEmpty(this.props.error.inventory) && this.props.error.inventory}</p>
				<div className="row">
					{shopItems.map((item) => {
						let cItem;
						// eslint-disable-next-line no-cond-assign
						let userCargoCount = (cItem = cargo.find((c) => c.productName === item.productName))
							? cItem.amount
							: 0;
						return (
							<div
								className="col-md-5"
								key={item._id}
								style={{ border: '1px dotted black', padding: '10px', margin: '20px' }}
							>
								<img
									src={'./assets/' + item.productName + '.png'}
									style={{ maxWidth: '30%', float: 'right' }}
									alt={item.productName}
								/>
								<b>Name</b>: {item.productName} <br />
								<b>Available: </b>
								{item.productAmount} x<br />
								<b>On your boat: </b>
								{userCargoCount} x<br />
								<b>Local price:</b>
								{item.productPrice}
								<img src={doubloon} alt="D" />
								<br />
								<br />
								<input
									type="number"
									style={{ width: '50px' }}
									min={0}
									max={item.productAmount}
									defaultValue={0}
									id={'buy' + item.productName}
									onChange={this.onChange}
								/>
								<button
									className="btn btn-success"
									onClick={() => this.handleBuyCargoButton(item.productName)}
								>
									Buy
								</button>
								<br />
								<br />
								<input
									type="number"
									style={{ width: '50px' }}
									min={0}
									max={userCargoCount}
									defaultValue={0}
									id={'sell' + item.productName}
									onChange={this.onChange}
								/>
								<button
									className="btn btn-danger"
									onClick={() => this.handleSellCargoButton(item.productName)}
								>
									Sell
								</button>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

ShopTab.propTypes = {
	cargo: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
	cargo: state.cargo,
	error: state.errors,
});

const mapDispatchToProps = {
	getAllShopItems,
	buyCargoUser,
	sellCargoUser,
	updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopTab);
